import React from "react";
import {Introduction} from "../components/company/introduction";
import {Goals} from "../components/company/goals";
import {NormalLayout} from "../components/layouts/normal_layout";
import {SEO} from "../components/seo";

const Company: React.FC = () => {

  return (
      <NormalLayout type="company">
        <SEO title="Profile | UV Tech by Rendev" description="Profile | UV Tech by Rendev" />
        <Introduction />
        <Goals />
      </NormalLayout>
  )
}

export default Company;