import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PromoSection} from "../common/promo_section";
import {graphql, useStaticQuery} from "gatsby";
import GatsbyImage from "gatsby-image";

export const Introduction: React.FC = () => {
  const {t, i18n} = useTranslation();
  const currentLanguage = i18n.language;

  const [markdownText, setMarkdownText] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    const fetchMarkdown = async (): Promise<void> => {
      const markdownFile = await import(
        `./introduction.${currentLanguage}.md`
        );
      setMarkdownText(markdownFile);
      setIsLoading(false);
    };
    fetchMarkdown();
  }, [currentLanguage]);

  const data = useStaticQuery(graphql`
  query {
  promo: file(relativePath: {eq: "promo/contact.jpg"}) { ...promoImage } 
  }`)

  return (
      <PromoSection>
        {markdownText ?
          <div className="page-promo-text" dangerouslySetInnerHTML={{__html: markdownText.html}}/> : null
        }
        <div className="page-promo-image">
          <div className="image-holder">
            <div className="image">
              <GatsbyImage
                style={{height: "100%", width: "100%"}}
                fluid={data.promo.childImageSharp.fluid}
              />
            </div>
          </div>
        </div>
      </PromoSection>
  )
}
