import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Section} from "../common/section";

export const Goals: React.FC = () => {
  const {t, i18n} = useTranslation();
  const currentLanguage = i18n.language;

  const [markdownText, setMarkdownText] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    const fetchMarkdown = async (): Promise<void> => {
      const markdownFile = await import(
          `./goals.${currentLanguage}.md`
          );
      setMarkdownText(markdownFile);
      setIsLoading(false);
    };
    fetchMarkdown();
  }, [currentLanguage]);

  return (
      <Section>
        {markdownText ?
            <div dangerouslySetInnerHTML={{__html: markdownText.html}}/> : null
        }
        <a className="external" href="http://rendev.solutions/">{t('company.goals.link')}</a>
      </Section>
  )
}
